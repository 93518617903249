$(function () {
	'use strict';


	if($('body.home').length){

		// スライドショー PC
		var $panel01 = $('.c-hero__slide');
		$panel01.psycle({
			transition: 'fade',
			startIndex: 0,
			duration: 1000,
			delay: 5000,
			auto: true,
			repeat: 'loop',
			draggable: false,
			swipeable: false,
		});
		setTimeout(function () {
			var p01 = $panel01.data('psycle');
			p01.marker(1000, $.PsycleEvent.PANEL_CHANGE_START).appendTo('.c-hero__marker');
		}, 200);

		// お知らせタブ切り替え
		var newsCategoryContents = $('.c-news__list-article > div');
		var newsCategoryItem = $('.c-news__list-category button');
		newsCategoryContents.hide();
		$('.c-news__list-article #all').show();
		newsCategoryItem.on('click', function () {
		var tab_id = $(this).data('news-tag');
		newsCategoryItem.attr('data-current', 'false');
		$(this).attr('data-current', 'true');
		newsCategoryContents.hide();
		$('#' + tab_id).show();
		// newsCategoryContents.bcBoxAlignHeight(
		//  {
		//      768: 1,
		//      Infinity: 2,
		//  },
		//  "li:not([data-article='pickup'])"
		// );
		});

	}
});
