$(window).on("load",function(){
});
$(function() {
	'use strict';

	// スクロールアニメーション
	new WOW().init();

	var $body = $('body');

	//SVGスプライト
	$.ajax({
		type: 'get',
		url: '/wp/wp-content/themes/torizenfoods/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
	}).done(function(data) {
		var svg = $(data).find('svg');
		$('body').prepend(svg);
	});

	//ページ内スクロール
	$.smooziee();

	// 定数定義
	var BREAK_POINT_SM = 768;
	var BREAK_POINT_XL = 1100;

	// スクロールアニメーション
	var scrollOption = {
		viewFactor: 0.01,
		duration: 1500,
		beforeReveal: function (domEl) {
			domEl.classList.add('is-animating');
		},
	};

	// CSS `object-fit` ポリフィル for IE11 & Edge
	/* global objectFitImages */
	objectFitImages();

	// 変数
	var body = $('body');
	var navItemLink = $('.c-nav-global__list li a');
	var menu_btn = $('.c-header__btn-global');
	var menuText = $(".c-header__btn-global span[data-type='text']");
	var hd = $('header');
	var gnav = $('.c-header .c-nav-global');

	// ローディング
	var h = $(window).height();
	$('.c-loading ,.c-loading__body').height(h);

	function stopload() {
		body.attr('data-loading', 'false');
		$('.c-loading__body').fadeOut();
		$('.c-loading')
			.delay(900)
			.fadeOut(800);

		if(body.hasClass('home')) {
			// setTimeout(function () {
			// 	$(".c-hero__deco").attr('data-show', 'true');
			// }, 1000);
		}
	}
	stopload();

	setTimeout(stopload(), 5000);


	// バーガーパーツにスクロールアニメーション実装する
	function scrollAnimation() {
		ScrollReveal().reveal(".c-page-home__news", scrollOption); // TOPのNEWS
		ScrollReveal().reveal(".c-page-home__bnr-page-list > li", scrollOption); // TOPのバナー
		ScrollReveal().reveal(".c-page-home__bnr-site-list > li", scrollOption); // TOPのバナー
		ScrollReveal().reveal(".c-page-sub__detail > h2", scrollOption); // 第三階層のタイトル
		// ScrollReveal().reveal(".c-content-main > div", scrollOption); // バーガーパーツ
		// ScrollReveal().reveal('.c-news__list-article > ul > li', scrollOption); // ブログ記事一覧
		// ScrollReveal().reveal('.c-news__detail', scrollOption); // ブログ記事詳細
		// ScrollReveal().reveal('.c-pagination', scrollOption); // ページネーション
	}

	scrollAnimation();

	$('.c-news__list-category button').on('click', function () {
		scrollAnimation();
	});

	/**
	 * ヘッダーまわり
	 */

	//グロナビのカレント表示
	var dataPath = body.attr('data-path');
	if (body.hasClass('c-page-sub')) {
		navItemLink.each(function () {
			var navUrl = $(this).attr('href');

			if (dataPath.indexOf(navUrl) === 0) {
				$(this).attr('data-current', 'true');
				dataPath = ''; // iOSバグ対策
			}
		});
	}

	//サイトマップ
	$('ul.ul-level-2:not(:has(li))').css('display','none');

	/**
	 * サイドナビのSPメニュー
	 */
	$(".c-nav-local__index a[data-index='true']").on('click', function() {
		$(this)
			.next()
			.slideToggle();
		$(this).toggleClass('open');
		return false;
	});



	function globalNavPcLayout() {
		hd.attr('data-toggle', 'false');
		gnav.stop(true, false).fadeOut();
		menuText.text('MENU');
	}

	// グロナビボタン押した時
	menu_btn.on('click', function () {
		if (hd.attr('data-toggle') == 'true') {
			globalNavPcLayout();
		} else {
			gnav.stop(true, false).fadeIn();
			hd.attr('data-toggle', 'true');
			menuText.text('CLOSE');
		}
		return false;
	});

    /*クリックボックス*/
    $(".clickbox").click(function(){
         if($(this).find("a").attr("target")=="_blank"){
             window.open($(this).find("a").attr("href"), '_blank');
         }else{
             window.location=$(this).find("a").attr("href");
         }
     return false;
     });

    /*ギャラリーリンク*/
    $(".gallerylink figure").click(function(){
         if($(this).find("a").attr("target")=="_blank"){
             window.open($(this).find("a").attr("href"), '_blank');
         }else{
             window.location=$(this).find("a").attr("href");
         }
     return false;
     });

	function sideNavLayout() {
			$('.c-nav-local__index ul').removeAttr('style');
			$(".c-nav-local__index a[data-index='true']").removeClass('open');
	}
	sideNavLayout();
});