$(function () {
    // カタ自動補完
    $.fn.autoKana('#name', '#kana', {
        katakana : true  //true：カタカナ、false：ひらがな（デフォルト）
    });
});

// エンターキーでのボタン動作阻止
function submitStop(e){
  if (!e) var e = window.event;
  if(e.keyCode == 13)
  return false;
}
window.onload = function (){
  var list = document.getElementsByTagName("input");
  for(var i=0; i<list.length; i++){
    if(list[i].type == 'email' || list[i].type == 'password'|| list[i].type == 'text'|| list[i].type == 'number'|| list[i].type == 'tel'){
      list[i].onkeypress = function (event){
        return submitStop(event);
      };
    }
  }
}